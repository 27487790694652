
































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import FillableDocumentFilter from "@/dto/archive/FillableDocumentFilter";
import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";
import DocumentService from "@/services/DocumentService";
import FillableDocumentDataDTO from "@/dto/archive/FillableDocumentDataDTO";
import RequestIT2104Modal from "@/components/archive/it2104/RequestIT2104Modal.vue";
import Workspaces from "@/state/Workspaces";
import RouteNames from "@/router/RouteNames";
import { FillableDocumentStatus } from "@/dto/archive/FillableDocumentStatus";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class IT2104List extends Vue {

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  private successful = false;

  private message = "";

  private forms: Array<FillableDocumentDataDTO> = [];

  mounted(): void {
      this.loadForms();
  }

  showModal(): void {
    this.$modal.show(
      RequestIT2104Modal,
      {
          companyId: Workspaces.getCurrent.id,
          onSaved: this.onSaved,
      }
    );
  }

  onSaved(sublimeFilling: boolean, id: number): void {
    if (sublimeFilling) {
      this.goToForm(id);
    } else {
      this.loadForms();
    }
  }

  loadForms(): Promise<void> {
    this.startLoading();
    this.message = "";
    const filter = new FillableDocumentFilter();
    filter.requestingCompanyId = Workspaces.getCurrent.id;
    filter.documentType = FillableDocumentType.IT_2104;
    return DocumentService.getFillableByFilter(filter).then(
        success => this.forms = success.data.data,
        error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
    ).then(() => this.stopLoading());
  }

  onFormClick(form: FillableDocumentDataDTO): void {
    if (form.status === FillableDocumentStatus.NEW || form.status === FillableDocumentStatus.BEING_FILLED_OUT) {
      this.goToForm(form.id);
    }
    if (form.status === FillableDocumentStatus.SIGNED) {
      Vue.prototype.$docUtils.download(form.signedFile!.id);
    }
  }

  private goToForm(id: number) {
    this.$router.push({name: RouteNames.ARCHIVE_IT2104_INTERNAL, params: { id: `${id}`} });
  }

}
